<template>
  <div class="page my_course right_connent">
    <!-- 头部 -->
    <!-- <nav_top /> -->

    <div class="connent_bg">
      <div class="live" v-if="my_live_list.length > 0">
        <div class="title">近期直播</div>
        <ul class="live_ul">
          <div
            class="live_item"
            v-for="(live_item, index) in my_live_list"
            :key="index"
          >
            <a
              :href="
                '/course_watch/' +
                live_item.course_id +
                '/' +
                live_item.live.chapter_id +
                '/' +
                live_item.live.id
              "
              target="_blank"
            >
              <div class="title letterspace1">
                {{ live_item.live.section_name }}
              </div>
              <div class="course_name letterspace1">
                {{ live_item.live.course_name }}
              </div>
              <div class="bottom">
                <div class="left">
                  <div class="time">
                    <img src="@/assets/login/time-line.png" alt="" />
                    {{ live_item.live.start_time }}
                  </div>
                  <div
                    v-if="live_item.live.section_status == 1"
                    class="btn_ sign letterspace1"
                  >
                    已预约
                  </div>
                  <div
                    v-if="live_item.live.section_status == 2"
                    class="btn_ living letterspace1"
                  >
                    <!-- <span class="zb"></span> -->
                    <img
                      src="@/assets/common/live_icon.gif"
                      alt=""
                      style="margin-right: 10px; width: 13px"
                    />
                    直播中
                  </div>
                  <div
                    v-if="live_item.live.section_status == 3"
                    class="btn_ lived letterspace1"
                  >
                    直播结束
                  </div>
                  <div
                    v-if="live_item.live.section_status == 4"
                    class="btn_ playback letterspace1"
                  >
                    回放
                  </div>
                  <div
                    v-if="live_item.live.section_status == 5"
                    class="btn_ noplayback letterspace1"
                  >
                    无回放
                  </div>
                </div>
                <div class="teac">
                  <img :src="live_item.live.teacher_avatar_oval" alt="" />
                </div>
              </div>
            </a>
          </div>
        </ul>
      </div>
      <div class="course">
        <div class="title flex">
          <p>我的课程</p>
          <div class="search_wrap">
            <el-input
              v-model="search_value"
              placeholder="搜索我的课程"
              :clearable="true"
              @keyup.enter.native="handleSearch"
              @clear="handleSearch"
            >
              <i
                @click="handleSearch"
                slot="suffix"
                style=""
                :class="[
                  'search_icon',
                  'el-input__icon',
                  'el-icon-search',
                  search_icon_on ? 'on' : '',
                ]"
              ></i>
            </el-input>
          </div>
        </div>
        <ul class="course_ul" v-if="my_course_list.length > 0">
          <template v-for="(item, index) in my_course_list"
            ><a
              class="coursewrap"
              :href="'/course_watch/' + item.course_id"
              target="_blank"
              :key="index"
            >
              <li class="course_item">
                <div class="top">
                  <div class="name letterspace1">{{ item.course_name }}</div>
                  <div class="label_wrap">
                    <div class="label letterspace1">{{ item.course_tag }}</div>
                    <span>
                      共{{ item.course_sum_section }}节
                      {{ item.learning_rate > 0 ? "已学" : "" }}
                    </span>
                    <span class="state" v-if="item.learning_rate > 0">
                      {{ item.learning_rate }}%</span
                    >
                    <span class="state no" v-else>未学习</span>
                  </div>
                  <div class="teacher">
                    <template v-if="item.teacher.length">
                      <template v-for="(item_teacher, i) in item.teacher">
                        <div v-if="i < 5" class="item_teac" :key="i">
                          <img :src="item_teacher.teacher_avatar" alt="" />
                          <div class="text">
                            {{ item_teacher.teacher_name }}
                          </div>
                        </div>
                      </template>
                    </template>
                    <template v-else>
                      <div class="item_teac">
                        <img
                          src="https://success-image.successkaoyan.com/teacher/jiangshituan.png"
                          alt=""
                        />
                        <div class="text">讲师团</div>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="btn_wrap">
                  <!-- <div class="class_btn">进入班群</div> -->
                  <div class="go_btn">开始学习</div>
                </div>
              </li>
            </a>
          </template>
        </ul>
        <div
          class="no_data"
          style="text-align: center; color: #999999; margin-top: 250px"
          v-show="total == 0"
        >
          <img style="width: 145px" src="@/assets/common/nodata.png" alt="" />
          <p style="margin-top: 10px">您还没有加入课程，去逛逛吧~</p>
        </div>
      </div>
      <div class="page_wrap">
        <el-pagination
          background
          :pager-count="5"
          :page-size="limit"
          :total="total"
          layout="total, prev, pager, next, jumper"
          @prev-click="prev_click"
          @next-click="next_click"
          @current-change="current_change"
          hide-on-single-page
          :current-page="page"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 底部 -->
    <!-- <footer_ /> -->
  </div>
</template>

<script>
// import live_item from "@/components/live_item.vue";
import { compare_down } from "@/config/cookie.js";
import api from "@/config/api.js";
export default {
  name: "my_course",
  data() {
    return {
      courrent_index: 1,

      my_course_list: [],
      my_live_list: [],
      page: 1,
      limit: 6,
      total: null,

      search_value: "",
      search_icon_on: false,
    };
  },
  created() {
    this.getData_course();
    this.getData_live();
  },
  methods: {
    async getData_course(word) {
      try {
        const res = await api.my_course({
          page: this.page,
          limit: this.limit,
          course_name: word,
        });
        if (res.code == 0) {
          let data = res.result;
          data.data.forEach((item, index) => {
            item.teacher.sort(compare_down("sort_id"));
          });
          this.my_course_list = data.data;

          this.total = data.count;
          if (data.length == 0) {
          } else {
          }
        } else {
          this.$message(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getData_live() {
      try {
        const res = await api.my_live();
        if (res.code == 0) {
          let data = res.result;
          this.my_live_list = data;
          // this.total = data.count;
          if (data.length == 0) {
          } else {
          }
        } else {
          this.$message(res.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // handleToWatch(course_id){
    //   this.$router.push('/course_watch/'+course_id)
    // },
    prev_click(e) {
      this.page = e;
      this.getData_course();
    },
    next_click(e) {
      this.page = e;
      this.getData_course();
    },
    current_change(e) {
      this.page = e;
      this.getData_course();
    },
    handleSearch() {
      var word = (this.search_value + "").replace(/^\s+|\s+$/g, "");
      if (word) {
        this.getData_course(word);
      } else {
        this.getData_course();
      }
    },
  },
  components: {},
  watch: {
    search_value: function (newVal, oldVal) {
      if (newVal) {
        var new_str = newVal.replace(/^\s+|\s+$/g, "");

        if (new_str) {
          this.search_icon_on = true;
        } else {
          this.search_icon_on = false;
        }
      } else {
        this.search_icon_on = false;
      }
    },
  },
};
</script>
<style>
.my_course .el-pagination.is-background .btn-next,
.my_course .el-pagination.is-background .btn-prev,
.my_course .el-pagination.is-background .el-pager li {
  background-color: #fff;
}
</style>

<style lang="less" scoped>
.right_connent {
  width: 100%;
  .nav_name {
    font-size: 20px;
    font-weight: bold;
    color: #1f1f1f;
    line-height: 28px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e9e9e9;
  }
}

.connent_bg .live_ul .live_item:nth-child(2n) {
  margin-right: 0 !important;
}
// .connent_bg .live_ul .live_item:nth-child(2n) {
//   margin-right: 24px !important;
// }
.connent_bg {
  padding-left: 24px;
  box-sizing: border-box;
  position: relative;
}

.page {
  background-color: #f5f6f8;
  min-height: calc(100vh - 451px);
  padding-bottom: 42px !important;
}
.zhanwei {
  height: 22px;
}
.connent_bg {
  .title {
    margin-bottom: 28px;
    text-align: left;
    font-size: 20px;

    font-weight: 600;
    color: #333333;
    line-height: 28px;
  }
  .live_ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .live_item {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
  .course_ul {
    text-align: left;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .coursewrap {
      margin-right: 24px;
      margin-bottom: 40px;
    }
    .coursewrap:nth-of-type(3n) {
      margin-right: 0px;
    }
    li:nth-of-type(3n) {
      margin-right: 0;
    }
    li {
      width: 282px;
      height: 213px;
      background: #ffffff;
      box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.06);
      border-radius: 8px;
      padding: 17px 19px;
      box-sizing: border-box;
      // margin-right: 24px;
      // margin-bottom: 40px;
      .name {
        font-size: 16px;

        font-weight: 600;
        color: #151515;
        word-break: break-all;
      }
      .label_wrap {
        display: flex;
        align-items: center;
        .label {
          // width: 56px;
          padding: 0 3px;
          height: 17px;
          border: 1px solid #00c87f;
          border-radius: 4px;
          font-size: 12px;

          font-weight: 400;
          color: #00c87f;
          //   line-height: 21px;
          text-align: center;
          margin: 9px 10px 10px 0;
          line-height: 18px;
        }

        span:nth-of-type(1) {
          font-size: 14px;

          font-weight: 400;
          color: #666666;
        }
        span:nth-of-type(2) {
          font-size: 14px;

          font-weight: 400;
          color: #f96158;
        }
        .state.no {
          color: #999;
          margin-left: 10px;
        }
      }
      .teacher {
        display: flex;
        align-items: center;
        // flex-wrap: wrap;
        overflow: hidden;
        .item_teac {
          margin-right: 6px;
          img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-bottom: 5px;
          }
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 12px;

          font-weight: 400;
          color: #999999;
        }
      }
      .btn_wrap {
        text-align: center;
        line-height: 28px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 14px;

        font-weight: 500;
        color: #ffffff;
        margin-top: 18px;
        position: absolute;
        bottom: 20px;
        right: 22px;

        .class_btn {
          width: 77px;
          height: 28px;
          border: 1px solid #999999;
          border-radius: 14px;
          box-sizing: border-box;
          color: #333333;
          margin-right: 10px;
        }
        .go_btn {
          width: 77px;
          height: 28px;
          background: #479dff;
          border-radius: 14px;
        }
      }
    }
    .course_item:hover {
      /*   // box-shadow: 0 0 10px #dce6f1;
  // box-shadow: 4px 4px 14px 0 rgba(0, 0, 0, .08); */
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
      transform: translateY(-10px);
      -moz-transform: translateY(-10px);
      -o-transform: translateY(-10px);
      -webkit-transform: translateY(-10px);
    }
    .course_item {
      // display: flex;
      // flex-direction: column;
      // justify-content: space-between;
      cursor: pointer;
      transition: all 0.4s;
      position: relative;
      a {
        display: flex;
        flex-direction: column;
        // justify-content: space-between !important;
        align-content: space-between;
      }
    }
  }
}

// 直播css
.live_wrap {
  display: flex;
  align-items: center;
}
.live_item:hover {
  box-shadow: 0 0 10px #dce6f1;
  // box-shadow: 4px 4px 14px 0 rgb(0 0 0 / 8%);
  transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  -webkit-transform: translateY(-10px);
}
.live_item {
  padding: 25px 24px 30px 30px;
  width: 434px;
  height: 192px;
  background: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  text-align: left;
  transition: all 0.4s;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 24px;
  .title {
    font-size: 16px;

    font-weight: 600;
    color: #151515;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0;
  }
  .course_name {
    font-size: 14px;

    color: #999;
    margin-bottom: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      .time {
        display: flex;
        align-items: center;
      }
      .time img {
        width: 15px;
        height: 15px;
        margin-right: 5px;
      }
      font-size: 16px;

      font-weight: 400;
      color: #f96158;
      .btn_ {
        width: 117px;
        height: 34px;
        // background: linear-gradient(90deg, #f98715 0%, #fea54b 100%);
        // background: linear-gradient(90deg, #f3473d 0%, #fc7169 100%);
        border-radius: 17px;
        font-size: 16px;

        font-weight: 600;
        color: #ffffff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        span {
          margin-right: 8px;
          display: inline-block;
          width: 11px;
          // padding-right: 20px;
          height: 24px;
          // background:url(../assets/login/time-line.png) ;
          background-position: 98px 0;
          background-repeat: no-repeat;
          background: url(../../assets/login/live_h_spanbg.png);
          -webkit-animation: live 0.8s steps(11) infinite;
          -moz-animation: live 0.8s steps(11) infinite;
          -ms-animation: live 0.8s steps(11) infinite;
          -o-animation: live 0.8s steps(11) infinite;
          animation: live 0.8s steps(11) infinite;
        }
      }
    }
    .teac {
      width: 65px;
      height: 86px;
      img {
        width: 65px;
        height: 86px;
      }
    }
  }
}
@-webkit-keyframes live {
  0% {
    background-position: 98px 0;
  }

  100% {
    background-position: 98px -264px;
  }
}

@-moz-keyframes live {
  0% {
    background-position: 98px 0;
  }

  100% {
    background-position: 98px -264px;
  }
}

@-ms-keyframes live {
  0% {
    background-position: 98px 0;
  }

  100% {
    background-position: 98px -264px;
  }
}

@-o-keyframes live {
  0% {
    background-position: 98px 0;
  }

  100% {
    background-position: 98px -264px;
  }
}

@keyframes live {
  0% {
    background-position: 98px 0;
  }

  100% {
    background-position: 98px -264px;
  }
}

.sign {
  background: linear-gradient(90deg, #f98715 0%, #fea54a 100%);
}

.living {
  background: linear-gradient(90deg, #f3453b 0%, #fc736b 100%);
}

.lived {
  color: #999999;
}

.playback {
  background: linear-gradient(90deg, #00ca79 0%, #00e4a7 100%);
}

.page_wrap {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
}
// 搜索我的课程
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search_wrap {
  width: 220px;
  /deep/.el-input__inner {
    border-radius: 20px;
  }
}
</style>
